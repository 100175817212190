export default [
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/Dashboard.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    component: () => import("@/views/customer/cart/cart.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("@/views/customer/checkout/view.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/account/profile",
    name: "profile",
    component: () => import("@/views/customer/profile/profile.vue"),
    meta: {
      layout: "full",
    },
  },
  // {
  //   path: '/account/profile/bank',
  //   name: 'my-bank',
  //   component: () => import('@/views/customer/profile/bank.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: "/account/profile/my",
    name: "my-profile",
    component: () => import("@/views/customer/profile/profile-my.vue"),
    meta: {
      layout: "full",
      resource: "information",
    },
  },
  {
    path: "/account/profile/edit",
    name: "profile-edit",
    component: () => import("@/views/customer/profile/profile-edit.vue"),
    meta: {
      layout: "full",
      resource: "information",
    },
  },
  {
    path: "/account/profile/address",
    name: "profile-address",
    component: () => import("@/views/customer/profile/address.vue"),
    meta: {
      layout: "full",
      resource: "addressbook",
    },
  },
  {
    path: "/account/profile/wallet",
    name: "anan-wallet",
    component: () => import("@/views/customer/profile/wallet.vue"),
    meta: {
      layout: "full",
    },
  },
  // {
  //   path: '/user/voucher-wallet',
  //   name: 'voucher-wallet',
  //   component: () => import('@/views/customer/voucher-wallet/view.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: "/new-pages",
    name: "new-pages",
    component: () => import("@/views/customer/new/view.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/order-list",
    name: "order-list",
    component: () => import("@/views/customer/order/order.vue"),
    meta: {
      layout: "full",
      resource: "order-list",
    },
  },
  {
    path: "/order/detail/:id",
    name: "order-detail",
    component: () => import("@/views/customer/order/order-detail.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/order/detail/view/:id",
    name: "order-detail-view",
    component: () => import("@/views/customer/order/order-detail-view.vue"),
  },
  {
    path: "/customer",
    name: "customer",
    component: () => import("@/views/customer/customer.vue"),
  },
  {
    path: "/employee",
    name: "employee",
    component: () => import("@/views/customer/employee.vue"),
  },
  {
    path: "/payment/create",
    name: "payment-create",
    component: () => import("@/views/customer/payment/create.vue"),
    meta: {
      layout: "full",
      resource: "checkbill",
    },
  },
  {
    path: "/payment/paycreate/:id",
    name: "payment-paycreate",
    component: () => import("@/views/customer/payment/paycreate.vue"),
    meta: {
      layout: "full",
      resource: "checkbill",
    },
  },
  {
    path: "/paymenttochina",
    name: "paymenttochina",
    component: () => import("@/views/customer/paymenttochina.vue"),
  },
  {
    path: "/finance/receipt",
    name: "receipt",
    component: () => import("@/views/customer/finance/receipt.vue"),
  },
  {
    path: "/order-print",
    name: "order-print",
    component: () => import("@/views/customer/order-print.vue"),
  },
  {
    path: "/shipment/payment",
    name: "payment",
    component: () => import("@/views/customer/shipment/payment.vue"),
  },
  {
    path: "/export",
    name: "export",
    component: () => import("@/views/customer/export.vue"),
    meta: {
      layout: "full",
      resource: "exportproducts",
    },
  },
  {
    path: "/export-detail/:id",
    name: "export-detail",
    component: () => import("@/views/customer/export/detail.vue"),
    meta: {
      layout: "full",
      resource: "exportproducts",
    },
  },
  {
    path: "/finance/refill",
    name: "refill",
    component: () => import("@/views/customer/finance/refill.vue"),
  },
  {
    path: "/lost-package",
    name: "lost-package",
    component: () => import("@/views/customer/lost-package/view.vue"),
    meta: {
      layout: "full",
      resource: "lost_package",
    },
  },
  {
    path: "/lost-package-add",
    name: "lost-package-add",
    component: () => import("@/views/customer/lost-package/add.vue"),
    meta: {
      layout: "full",
      resource: "lost_package",
    },
  },
  {
    path: "/lost-package-edit/:id",
    name: "lost-package-edit",
    component: () => import("@/views/customer/lost-package/edit.vue"),
    meta: {
      layout: "full",
      resource: "lost_package",
    },
  },
  {
    path: "/balance",
    name: "balance",
    component: () => import("@/views/customer/balance.vue"),
    meta: {
      layout: "full",
      resource: "balance",
    },
  },
  {
    path: "/warehouse/lot",
    name: "lot",
    component: () => import("@/views/customer/warehouse/lot.vue"),
  },
  {
    path: "/warehouse/thai",
    name: "thai",
    component: () => import("@/views/customer/warehouse/thai.vue"),
  },
  {
    path: "/warehouse/qc",
    name: "qc",
    component: () => import("@/views/customer/warehouse/qc.vue"),
  },
  {
    path: "/warehouse/section",
    name: "section",
    component: () => import("@/views/customer/warehouse/section.vue"),
  },
  {
    path: "/tracking",
    name: "tracking",
    component: () => import("@/views/customer/tracking/tracking.vue"),
    meta: {
      layout: "full",
      resource: "tracking",
    },
  },
  {
    path: "/tracking/create",
    name: "tracking-create",
    component: () => import("@/views/customer/tracking/tracking-add.vue"),
    meta: {
      layout: "full",
      resource: "tracking",
    },
  },
  {
    path: "/tracking/edit/:id",
    name: "tracking-edit",
    component: () => import("@/views/customer/tracking/tracking-edit.vue"),
    meta: {
      layout: "full",
      resource: "tracking",
    },
  },
  {
    path: "/tracking/detail/:id",
    name: "tracking-detail",
    component: () => import("@/views/customer/tracking/tracking-detail.vue"),
    meta: {
      layout: "full",
      resource: "tracking",
    },
  },
  {
    path: "/combine-track",
    name: "combine-track",
    component: () => import("@/views/customer/combine-track.vue"),
  },
  {
    path: "/china/transfer",
    name: "transfer",
    component: () => import("@/views/customer/china/transfer.vue"),
  },
  {
    path: "/china/alipay",
    name: "alipay",
    component: () => import("@/views/customer/china/alipay.vue"),
  },
  {
    path: "/china/payfor",
    name: "payfor",
    component: () => import("@/views/customer/china/payfor.vue"),
  },
  {
    path: "/notice",
    name: "notice",
    component: () => import("@/views/customer/notice.vue"),
    meta: {
      layout: "full",
      resource: "notify",
    },
  },
  {
    path: "/notice/:id",
    name: "notice-view",
    component: () => import("@/views/customer/notice-view.vue"),
    meta: {
      layout: "full",
      resource: "notify",
    },
  },
  {
    path: "/article",
    name: "article",
    component: () => import("@/views/customer/article.vue"),
  },
  {
    path: "/point",
    name: "point",
    component: () => import("@/views/customer/point/point.vue"),
    meta: {
      layout: "full",
      resource: "point",
    },
  },
  {
    path: "/point/withdraw",
    name: "point-withdraw",
    component: () => import("@/views/customer/point/withdraw.vue"),
  },
  {
    path: "/finance/withdraw",
    name: "withdraw",
    component: () => import("@/views/customer/finance/withdraw.vue"),
    meta: {
      layout: "full",
      resource: "withdraw_money",
    },
  },
  {
    path: "/china/exchange",
    name: "exchange",
    component: () => import("@/views/customer/china/exchange.vue"),
  },
  {
    path: "/goodsconfirm",
    name: "goodsconfirm",
    component: () => import("@/views/customer/goodsconfirm/goodsconfirm.vue"),
    meta: {
      layout: "full",
      resource: "goodsconfirm",
    },
  },
  {
    path: "/goodsconfirm/:id",
    name: "goodsconfirm-check",
    component: () =>
      import("@/views/customer/goodsconfirm/goodsconfirm-check.vue"),
    meta: {
      layout: "full",
      resource: "goodsconfirm",
    },
  },
  {
    path: "/report",
    name: "report",
    component: () => import("@/views/customer/report.vue"),
  },
  // {
  //   path: '/shop-mall',
  //   name: 'shop-mall',
  //   component: () => import('@/views/customer/mall.vue'),
  // },
  {
    path: "/product/:id",
    name: "product",
    component: () => import("@/views/customer/product/product.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product/1688/:id",
    name: "product-1688",
    component: () => import("@/views/customer/product/product_1688.vue"),
  },
  {
    path: "/product/taobao/:id",
    name: "product-taobao",
    component: () => import("@/views/customer/product/product_taobao.vue"),
  },
  {
    path: "/product/tmall/:id",
    name: "product-tmall",
    component: () => import("@/views/customer/product/product_tmall.vue"),
  },
  {
    path: "/product/weidian/:id",
    name: "product-weidian",
    component: () => import("@/views/customer/product/product_weidian.vue"),
  },
  {
    path: "/product/alibaba/:id",
    name: "product-alibaba",
    component: () => import("@/views/customer/product/product_alibaba.vue"),
  },

  {
    path: "/product2/:id",
    name: "product2",
    component: () => import("@/views/customer/product2/product.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/1688/:id",
    name: "product2-1688",
    component: () => import("@/views/customer/product2/product_1688.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/taobao/:id",
    name: "product2-taobao",
    component: () => import("@/views/customer/product2/product_taobao.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/tmall/:id",
    name: "product2-tmall",
    component: () => import("@/views/customer/product2/product_tmall.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/tb/:id",
    name: "product2-tb",
    component: () => import("@/views/customer/product2/product_tb.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/alibaba/:id",
    name: "product2-alibaba",
    component: () => import("@/views/customer/product2/product_alibaba.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/market.taobao/:id",
    name: "product2-market",
    component: () => import("@/views/customer/product2/product_market.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/product2/weidian/:id",
    name: "product2-weidian",
    component: () => import("@/views/customer/product2/product_weidian.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/mall_stock",
    name: "mall-stock",
    component: () => import("@/views/customer/mall-stock.vue"),
  },
  {
    path: "/suggestion",
    name: "suggestion",
    component: () => import("@/views/customer/suggestion.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("@/views/customer/faq.vue"),
    meta: {
      layout: "full",
      resource: "questions",
    },
  },
  {
    path: "/setting/user-type",
    name: "user-type",
    component: () => import("@/views/customer/setting/user-type.vue"),
  },
  {
    path: "/setting/role",
    name: "role",
    component: () => import("@/views/customer/setting/role.vue"),
  },
  {
    path: "/setting/product-type",
    name: "product-type",
    component: () => import("@/views/customer/setting/product-type.vue"),
  },
  {
    path: "/setting/shipping-type",
    name: "shipping-type",
    component: () => import("@/views/customer/setting/shipping-type.vue"),
  },
  {
    path: "/setting/bank",
    name: "bank",
    component: () => import("@/views/customer/setting/bank.vue"),
  },
  {
    path: "/setting/promotion",
    name: "promotion",
    component: () => import("@/views/customer/setting/promotion.vue"),
  },
  {
    path: "/setting/coupon",
    name: "coupon",
    component: () => import("@/views/customer/setting/coupon.vue"),
  },
  {
    path: "/setting/general",
    name: "general",
    component: () => import("@/views/customer/setting/general.vue"),
  },
  {
    path: "/setting/alipay",
    name: "alipay",
    component: () => import("@/views/customer/setting/alipay.vue"),
  },
  {
    path: "/setting/service-price",
    name: "service-price",
    component: () => import("@/views/customer/setting/service-price.vue"),
  },
  {
    path: "/setting/withdraw-unlock",
    name: "withdraw-unlockaq",
    component: () => import("@/views/customer/setting/withdraw-unlock.vue"),
  },

  // {
  //   path: '/commit-bills',
  //   name: 'user-commit-bill',
  //   component: () => import('@/views/customer/commit-bill.vue'),
  //   meta: {
  //     layout: 'full',
  //   },
  // },
  {
    path: "/line-notify",
    name: "line-notify",
    component: () => import("@/views/customer/profile/line-notify.vue"),
    meta: {
      layout: "full",
      resource: "line_notify",
    },
  },
  {
    path: "/book-account",
    name: "book-account",
    component: () => import("@/views/customer/profile/book-account.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/alipay",
    name: "alipay-list",
    component: () => import("@/views/customer/profile/alipay/alipay.vue"),
    meta: {
      layout: "full",
      resource: "alipay",
    },
  },
  {
    path: "/alipay-create",
    name: "alipay-create",
    component: () => import("@/views/customer/profile/alipay/alipay-add.vue"),
    meta: {
      layout: "full",
      resource: "alipay",
    },
  },
  {
    path: "/alipay/:id",
    name: "alipay-edit",
    component: () => import("@/views/customer/profile/alipay/alipay-edit.vue"),
    meta: {
      layout: "full",
      resource: "alipay",
    },
  },
  {
    path: "/transfer",
    name: "transfer-list",
    component: () => import("@/views/customer/profile/transfer/transfer.vue"),
    meta: {
      layout: "full",
      resource: "transfer",
    },
  },
  {
    path: "/transfer/:id",
    name: "transfer-edit",
    component: () =>
      import("@/views/customer/profile/transfer/transfer-edit.vue"),
    meta: {
      layout: "full",
      resource: "transfer",
    },
  },
  {
    path: "/transfer-create",
    name: "transfer-create",
    component: () =>
      import("@/views/customer/profile/transfer/transfer-add.vue"),
    meta: {
      layout: "full",
      resource: "transfer",
    },
  },
  {
    path: "/payfor",
    name: "payfor-list",
    component: () => import("@/views/customer/profile/payfor/payfor.vue"),
    meta: {
      layout: "full",
      resource: "payfor",
    },
  },
  {
    path: "/payfor-create",
    name: "payfor-create",
    component: () => import("@/views/customer/profile/payfor/payfor-add.vue"),
    meta: {
      layout: "full",
      resource: "payfor",
    },
  },
  {
    path: "/payfor/:id",
    name: "payfor-edit",
    component: () => import("@/views/customer/profile/payfor/payfor-edit.vue"),
    meta: {
      layout: "full",
      resource: "payfor",
    },
  },
  {
    path: "/payfor-refund-create",
    name: "payfor-refund-create",
    component: () =>
      import("@/views/customer/profile/payfor/payfor-refund-add.vue"),
    meta: {
      layout: "full",
      resource: "payfor",
    },
  },
  {
    path: "/payfor-refund/:id",
    name: "payfor-refund-edit",
    component: () =>
      import("@/views/customer/profile/payfor/payfor-refund-edit.vue"),
    meta: {
      layout: "full",
      resource: "payfor",
    },
  },
  {
    path: "/refill",
    name: "customer-refill",
    component: () => import("@/views/customer/profile/refill/refill.vue"),
    meta: {
      layout: "full",
      resource: "refill",
    },
  },
  {
    path: "/refill/:id",
    name: "customer-refill-view",
    component: () => import("@/views/customer/profile/refill/refill-view.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/refill-add",
    name: "customer-refill-add",
    component: () => import("@/views/customer/profile/refill/refill-add.vue"),
    meta: {
      layout: "full",
      resource: "refill",
    },
  },
  {
    path: "/member/user",
    name: "member-user",
    component: () => import("@/views/customer/profile/member/user.vue"),
    meta: {
      layout: "full",
      resource: "agent_user",
    },
  },
  {
    path: "/member/user-add",
    name: "member-user-add",
    component: () => import("@/views/customer/profile/member/user-add.vue"),
    meta: {
      layout: "full",
      resource: "agent_user",
    },
  },
  {
    path: "/member/user-edit/:id",
    name: "member-user-edit",
    component: () => import("@/views/customer/profile/member/user-edit.vue"),
    meta: {
      layout: "full",
      resource: "agent_user",
    },
  },
  {
    path: "/member/parcelcode",
    name: "member-parcelcode",
    component: () => import("@/views/customer/profile/member/parcelcode.vue"),
    meta: {
      layout: "full",
      resource: "agent_tracking",
    },
  },

  {
    path: "/member/parcelcode/create",
    name: "member-parcelcode-create",
    component: () =>
      import("@/views/customer/profile/member/parcelcode-add.vue"),
    meta: {
      layout: "full",
      resource: "agent_tracking",
    },
  },

  {
    path: "/member/parcelcode-edit/:id",
    name: "member-parcelcode-edit",
    component: () =>
      import("@/views/customer/profile/member/parcelcode-edit.vue"),
    meta: {
      layout: "full",
      resource: "agent_tracking",
    },
  },

  {
    path: "/user/QC",
    name: "QC-user",
    component: () => import("@/views/customer/QC-user.vue"),
    meta: {
      layout: "full",
      resource: "qc-postnumber",
    },
  },
];
