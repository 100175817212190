import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import admin from './routes/admin'
import auth from './routes/auth'
import customer from './routes/customer'

Vue.use(VueRouter)
const userData = getUserData()
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      redirect: {
        // eslint-disable-next-line no-nested-ternary
        name: userData ? (userData.role === 'customer' ? 'new-pages' : userData.role === 'admin' ? `${userData.ability[0].subject}` : 'auth-login') : 'auth-login',
      },
    },
    ...admin,
    ...customer,
    ...auth,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    if (to.matched.some(route => route.meta.resource)) {
      if (!canNavigate(to)) {
        next({ name: userData.role === 'customer' ? 'new-pages' : `${userData.ability[0].subject}` });
      }
    //   const checkre = userData.ability.find(ele => ele.resource === to.matched[0].meta.resource)
    //   if (!checkre) {
    //     next({ name: 'error-403' });
    //   }
    }
    return next()
  }
  // if (to && isLoggedIn) {
  //   console.log('ll')
  //   next(getHomeRouteForLoggedInUser(userData ? userData : null))
  // }

  return next()
})
// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
