import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
// eslint-disable-next-line import/no-extraneous-dependencies
import VueSkeletonLoader from 'skeleton-loader-vue'
import MarqueeText from 'vue-marquee-text-component'

import i18n from '@/libs/i18n'
// eslint-disable-next-line import/no-extraneous-dependencies
import loader from 'vue-ui-preloader'
// eslint-disable-next-line import/extensions
import commonMixin from './libs/commonMixin.js'
import router from './router'
import store from './store'
import App from './App.vue'

// loader css

// Axios Mock Adapter ทดสอบแชท
import '@/@fake-db/db'

// import Style customizer
import '@/assets/css/login.css'
import '@/assets/css/styles.css'
import '@/assets/css/mall.css'
import '@/assets/css/table.css'
import '@/assets/css/customs.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Check Mobile
Vue.prototype.isNotMobile = window.innerWidth > 768

Vue.component('vue-skeleton-loader', VueSkeletonLoader)
Vue.component('marquee-text', MarqueeText)

// Vue.use(i18n)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(loader)
// Composition API
Vue.use(VueCompositionAPI)

Vue.mixin(commonMixin)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
