import moment from 'moment-timezone'

export default {
  methods: {
    sharedMethod() {
      console.log('I am a shared methodหก')
    },
    FormateDateTh(val) {
      if (val) {
        const formattedDate = moment(val, 'YYYY-MM-DD')
          .locale('th') // Set the locale to Thai
          .format('DD MMMM YYYY')
        return formattedDate
      }
      return ''
    },
    Commas(x) {
      if (!x) {
        return 0;
      }
      if (x % 1 !== 0) {
        // return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        let roundedNumber = Math.round(x * 100) / 100; 
        return roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    CommasCue(x) {
      if (!x) {
        return 0
      }
      let roundedDownNumber = Math.floor(x * 1000000) / 1000000;
      return parseFloat(roundedDownNumber)
    },
    CommasUp(x) {
      if (!x) {
        return 0
      }
      let roundedDownNumber = Math.floor(x * 100) / 100;
      return parseFloat(roundedDownNumber)
    },
  },
}
